import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { ROUTES } from '../constant/routes';
// import { payment } from '../actions/PaymentActions';
import * as PaymentAction from '../actions/PaymentActions'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import payment_success from "../assets/images/payment_success.png"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 400,
    bgcolor: '#000000db',
    boxShadow: 24,
    borderRadius: "10px",
    border: "1px solid #ffffff",
    p: 4,
};

const Checkout = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory()
    const stripe1 = require('stripe')(props?.data?.secret_key);
    const [paymentDetails, setPaymentDetails] = useState("")
    const [success, setSuccess] = useState(false)
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const user = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    const { payment } = bindActionCreators(PaymentAction, dispatch)
    const [loader, setLoader] = useState(false)

    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        try {
            const result = await stripe.confirmPayment({
                elements,
                redirect: 'if_required'
            });
            console.log("result", result)

            if (result.error) {
                setLoader(false)
                toast.error(result.error.message)
            } else {
                setLoader(false)
                try {
                    const paymentIntent = await stripe1?.paymentIntents?.retrieve(result?.paymentIntent?.id);
                    setPaymentDetails(paymentIntent)
                    const today = new Date();
                    const dd = String(today.getDate()).padStart(2, '0');
                    const mm = String(today.getMonth() + 1).padStart(2, '0');
                    const yyyy = today.getFullYear();
                    const res = await axios.get("https://api.ipify.org/?format=json");
                    let userObj = {
                        ad_free: paymentIntent?.metadata?.adfree,
                        category_type: paymentIntent?.metadata?.category_type,
                        date: `${dd}/${mm}/${yyyy}`,
                        device_id: "",
                        device_type: "Web",
                        email: paymentIntent?.metadata?.email,
                        gateway: "stripe",
                        ip_address: res.data.ip,
                        location: "",
                        mobile_no: paymentIntent?.metadata?.mobile_no,
                        movie: Number(paymentIntent?.metadata?.movie) || null,
                        name: paymentIntent?.metadata?.name,
                        payment_amount: ((result?.paymentIntent?.amount) / 100),
                        payment_id: result?.paymentIntent?.id,
                        payment_method: result?.paymentIntent?.payment_method,
                        plan: Number(paymentIntent?.metadata?.plan),
                        promocode: paymentIntent?.metadata?.promocode,
                        series: Number(paymentIntent?.metadata?.series) || null,
                        transaction_type: paymentIntent?.metadata?.transaction_type,
                        user: user_id,
                        coin: null
                    }
                    // dispatch(payment(userObj));
                    const payment_api = await payment(userObj)
                    if (payment_api?.statuscode == 200) {
                        // toast.success("Payment success")
                        setSuccess(true)
                        setTimeout(() => {
                            setSuccess(false)
                            if (paymentIntent?.metadata?.slug) {
                                history.push(paymentIntent?.metadata?.slug ? paymentIntent?.metadata?.slug : ROUTES.home)
                            }
                            else {
                                history.push(ROUTES.home)
                            }

                        }, 3000);
                    }
                    else {
                        toast.error("Something went wrong !")
                    }


                } catch (error) {
                    console.error('Error retrieving payment intent:', error);
                }

            }
        }
        catch (error) {
            console.error('Error confirming payment:', error);

        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <Container>
                <div className='payment-stripe-form' style={{ margin: "auto", textAlign: "center" }}>

                    <PaymentElement />
                    {
                        loader ?
                            <div className='mt-3'>
                                <CircularProgress />
                            </div>
                            :
                            <>
                                <div className='mt-4' style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                                    {/* <p>Pay {props?.data?.amount + " " + props?.data?.currency.toUpperCase()}</p> */}
                                    <button className={"btn btn-hover iq-button text_small mt-3"} disabled={!stripe}>PAY NOW</button>
                                </div>
                                {
                                    success ?
                                        <Modal
                                            open={success}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
                                                    Payment Success
                                                </Typography>
                                                <hr />
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={payment_success} alt='regal_logo' style={{ width: "200px" }} />
                                                </div>
                                                {/* <hr /> */}
                                                <div>
                                                    <div className='pt-3' style={{ display: "flex", justifyContent: "center" }}>
                                                        <p>Payment ID</p>
                                                    </div>
                                                    <div className='' style={{ display: "flex", justifyContent: "center" }}>
                                                        <p>{paymentDetails?.id}</p>
                                                    </div>
                                                </div>
                                                <div className='pt-2' style={{ display: "flex", justifyContent: "center" }}>
                                                    <p>Email : {user?.email}</p>
                                                </div>
                                            </Box>
                                        </Modal>
                                        :
                                        <></>
                                }

                            </>
                    }
                </div>
            </Container>
        </form>
    )
};

export default Checkout;
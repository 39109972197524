import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import { all_avatar_list, profile_create } from "../../../../actions/HomeActions"
import * as HomeAction from '../../../../actions/HomeActions'
import { useSelector } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import logo from '../../../../assets/regal_images/regal_logo.png'
import OtpInput from 'react-otp-input';
import FormControl from "@mui/material/FormControl";
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { PROFILE_MESSAGE } from '../../../../Actiontype/ActionType';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import AlertMessage from '../../common/AlertMessage';
import PhoneInput from 'react-phone-input-2';
import styles from "./form.module.css";
import { Box, Modal, Typography } from '@mui/material';

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

const ToggleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#fff",
        '&:hover': {
            backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#fff",
    },
    '& .MuiSwitch-switchBase': {
        color: "#fff",
        '&:hover': {
            backgroundColor: alpha("#fff", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: "#fff",
    },
}));
const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const Profile = (props) => {
    const location = useLocation()

    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id || location?.state?.user_id
    const user = JSON.parse(sessionStorage.getItem('user')) || null
    const dispatch = useDispatch()

    const [form, setForm] = useState({ profileType: "main", interest: "", user: location?.state?.user_id || user_id })
    const [formData, setFormData] = useState({})
    let history = useHistory()
    const [userId, setUserId] = useState(location?.state?.user_id || null)
    console.log(location?.state)
    const [desktop, setDesktop] = useState(false)

    const { submitInfo } = bindActionCreators(HomeAction, dispatch)
    const [show, setShow] = useState(location?.state?.firsttime == true ? false : true);
    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
        if (location?.state?.firsttime == true) {
            const unblock = history.block((location, action) => {
                if (action === 'POP') {
                    let formData = new FormData()
                    formData.append("id", userId)
                    // Handle the back button event
                    if (window.confirm('Are you sure you want to go back?')) {
                        unblock(); // Allow navigation
                        history.goBack();
                        sessionStorage.removeItem("user")
                        dispatch(HomeAction.delete_user(formData))
                    } else {
                        history.replace('/login'); // Prevent navigation
                        sessionStorage.removeItem("user")
                        dispatch(HomeAction.delete_user(formData))

                    }
                    setDesktop(true)
                }
            });

            return () => {
                if (location?.state?.firsttime == true) {
                    unblock();

                }
            };
        }

    }, [ location?.state?.user_id])
    const avatars = useSelector((state) => state.home.avatars);
    console.log(desktop)
    useEffect(() => { dispatch(all_avatar_list()) }, [])


    const [showAlert, setShowAlert] = useState(false);

    useMemo(() => {
        setForm({ ...form, profileImage: IMAGE_ENDPOINT + avatars?.data?.[0]?.avatarImage, avatar: avatars?.data?.[0]?.id })

    }, [avatars])
    const message = useSelector((state) => state.home.profile_message);

    useMemo(() => {
        if (message?.statuscode == 200 && message?.message != undefined) {
            sessionStorage.setItem("isLoggedIn", true)
            history.replace("/profile_selection")
            console.log("success")
            dispatch({ type: PROFILE_MESSAGE, payload: null })
        }
    }, [message])
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(form)
        if (form?.profile_secure) {
            if (form?.profile_pin) {
                form.user = user_id

                dispatch(profile_create({ ...form, profile_secure: JSON.stringify(form?.profile_secure) }));
            }
            else {
                toast.error("Profile pin is required.")
            }
        }
        else {
            form.user = user_id

            dispatch(profile_create({ ...form, profile_secure: JSON.stringify(form?.profile_secure) }));
        }
        // if (form?.profile_pin) {
        // dispatch(profile_create({ ...form, profile_secure: JSON.stringify(form?.profile_secure) }));
        setForm({ ...form, profile_pin: "" })
        // }

    }

    const profileComplete = async (e) => {
        e.preventDefault()
        const res = await axios.get("https://api.ipify.org/?format=json");
        formData.ip_address = res?.data?.ip
        if (location?.state?.email) {
            formData.email = location?.state?.email
        }
        else {
            formData.mobile_number = location?.state?.mobileNumber

        }
        formData.flag = location?.state?.email ? "email" : "mobile"
        if (formData.mobile_number && formData.email && formData.email !== "" && formData.mobile_number !== "") {
            console.log(formData)
            const resData = await submitInfo(formData)
            console.log(resData)
            if (resData?.statuscode == 200) {
                setShow(true)

            }
            else {
                toast.error(resData?.message)
            }
        }
        else {
            toast.error("Please fill the required field")
        }

    }

    console.log(formData)
    return (
        <>
            {/* <div className={`rtl-box ${show===true?'show':''}`} >
                <button type="button" className="btn btn-light rtl-btn">
                        <svg onClick={()=>setShow(!show)} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 20 20" fill="white">
                        <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                        </svg>
                </button>
                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => {props.rtlModeAction('ltl')}}   data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => {props.rtlModeAction('rtl')}}  data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div> */}
            {/* <section className="sign-in-page" style={{ backgroundImage: "url(" + bg + ")" }}> */}
            <section className="sign-in-page" style={{ marginTop: "1rem" }}>
                <AlertMessage />
                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            {/* <div className="sign-user_card "> */}
                            <div className="sign-user_card ">

                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">
                                        <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>Create Profile</p>
                                        {
                                            !show &&
                                            <form onSubmit={profileComplete}>
                                                <Row style={{ display: "flex", justifyContent: "center" }}>


                                                    <Col className='pt-2' md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                        <input type="text" onChange={(event) => (setFormData({ ...formData, firstName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="First Name" autoComplete="off" required />
                                                    </Col>
                                                    <Col className='pt-2' md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                        <input type="text" onChange={(event) => (setFormData({ ...formData, lastName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Last Name" autoComplete="off" required />
                                                    </Col>
                                                    {
                                                        location?.state?.email ?
                                                            // <Col className='pt-2' md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                            //     <input style={{ borderRadius: "1rem" }} type="number" onChange={(event) => (setFormData({ ...formData, mobile_number: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Mobile Number" autoComplete="off" required />
                                                            // </Col>
                                                            <Col className='pt-2' md="6" style={{ display: "flex", justifyContent: "center" }}>

                                                                <PhoneInput
                                                                    labelId="demo-simple-select-label"
                                                                    country={"in"}
                                                                    required={true}
                                                                    // value={form?.phoneNumber}

                                                                    onChange={(phone, countryCode) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            mobile_number:
                                                                            // mobile_number: "+" + countryCode.dialCode +
                                                                                (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                                                                    : phone.substring(2)),
                                                                            countryCode: "+" + countryCode.dialCode,
                                                                        });

                                                                    }}
                                                                    isValid={(value, country) => {
                                                                        if (country.dialCode == "91" && value[2] < 6) {
                                                                            return 'Invalid Number'
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }}
                                                                    dropdownClass={styles.phoneInputDropDown}
                                                                    highlightClass={styles.highlight}
                                                                    buttonClass={styles.phoneInputButton}
                                                                    inputClass={styles.phoneInput}
                                                                />
                                                            </Col>
                                                            :
                                                            <Col className='pt-2' md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                                <input style={{ borderRadius: "1rem" }} type="email" onChange={(event) => (setFormData({ ...formData, email: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Email" autoComplete="off" required />
                                                            </Col>
                                                    }

                                                    <Col className='pt-2' md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                        <input type="date" onChange={(event) => (setFormData({ ...formData, birthDate: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Date of Birth" autoComplete="off" required />
                                                    </Col>
                                                    <Col className='pt-2' md="6">
                                                        <Form.Group>
                                                            <Form.Label style={{ color: "white" }}>Gender</Form.Label>
                                                            <div key={`inline-radio`} className="mb-3">
                                                                <Form.Check
                                                                    required
                                                                    inline
                                                                    label="Male"
                                                                    name="gender"
                                                                    type='radio'
                                                                    id={`inline-radio-1`}
                                                                    onClick={(event) => (setFormData({ ...formData, gender: "Male" }))}
                                                                />
                                                                <Form.Check
                                                                    required
                                                                    inline
                                                                    label="Female"
                                                                    name="gender"
                                                                    type='radio'
                                                                    id={`inline-radio-2`}
                                                                    onClick={(event) => (setFormData({ ...formData, gender: "Female" }))}

                                                                />
                                                                <Form.Check
                                                                    required
                                                                    inline
                                                                    label="Other"
                                                                    name="gender"
                                                                    type='radio'
                                                                    id={`inline-radio-3`}
                                                                    onClick={(event) => (setFormData({ ...formData, gender: "Other" }))}

                                                                />
                                                            </div>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please enter the Date of Birth.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='pt-2' md="6">
                                                    </Col>
                                                </Row>
                                                <div className='pt-3' style={{ display: "flex", justifyContent: "end" }}>
                                                    <button type='submit' style={{ textAlign: "center" }} className="btn btn-hover" >Next</button>
                                                </div>
                                            </form>
                                        }
                                        {
                                            show &&
                                            <Form onSubmit={(event) => handleSubmit(event)}>

                                                <Row className='pt-4' style={{ display: "flex", justifyContent: "center" }}>


                                                    <Col md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                        <Form.Group>
                                                            {form?.profileImage != undefined && avatars ? <img src={form?.profileImage} width={"100px"} className="rounded img-fluid d-block mx-auto mb-3" /> : <p></p>}


                                                            <Form.Control type="text" isInvalid={form?.profileName == undefined || form?.profileName == ""} onChange={(event) => (setForm({ ...form, profileName: event.target.value }))} className="mb-0" id="exampleInputEmail2" placeholder="Profile Name" autoComplete="off" required />
                                                            {/* <Form.Control.Feedback  type="invalid" style={{textAlign:"center"}}>
              Please enter the Profile name.
            </Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {/* <Row style={{ display: "flex", justifyContent: "center" }}>


                                                    <Col md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                        <Form.Group>
                                                            <FormControlLabel control={<ToggleSwitch checked={form?.profile_secure}
                                                                onChange={(event) => (setForm({ ...form, profile_secure: event.target.checked, profile_pin: event.target.checked ? form?.profile_pin : "" }))} />}
                                                                labelPlacement="start" label="Secure your profile with pin" />

                                                        </Form.Group>
                                                    </Col>
                                                </Row> */}
                                                {form?.profile_secure && <Row style={{ display: "flex", justifyContent: "center" }}>


                                                    <Col md="6" style={{ display: "flex", justifyContent: "center" }}>
                                                        <Form.Group>

                                                            <FormControl fullWidth >
                                                                <OtpInput
                                                                    containerStyle={{ display: "flex", justifyContent: "space-evenly" }}
                                                                    value={form?.profile_pin}
                                                                    onChange={(a) => (setForm({ ...form, profile_pin: a }))}
                                                                    numInputs={4}
                                                                    inputStyle={{ fontSize: "3em", marginBottom: "1rem", padding: "0", border: "none", borderRadius: "0", borderBottom: "2px solid white", background: "transparent" }}
                                                                    inputType='text'
                                                                    renderSeparator={<span> &nbsp;&nbsp; </span>}
                                                                    renderInput={(props) => <input {...props} />}
                                                                />

                                                                <Form.Label style={{ display: "flex", justifyContent: "center", color: "white", marginBottom: "2rem" }}>Enter the 4 digit Pin</Form.Label>
                                                            </FormControl>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>}
                                                <Row style={{ display: "flex", justifyContent: "center", overflowY: "hidden" }}>
                                                    {avatars?.data?.map((ele, index) => <Col key={index} md="2" style={{ display: "flex", justifyContent: "center" }}>
                                                        <img src={IMAGE_ENDPOINT + ele?.avatarImage} onClick={() => (setForm({ ...form, profileImage: IMAGE_ENDPOINT + ele?.avatarImage, avatar: ele?.id }))} width={"100px"} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />
                                                    </Col>)}

                                                </Row>

                                                <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>

                                            </Form>
                                        }

                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                open={desktop}
                onClose={() => setDesktop(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={styleforAlert}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                    </div>
                    <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                        <div onClick={() => setDesktop(false)}>
                            <HighlightOffIcon style={{ height: "40px", cursor: "pointer" }} />
                        </div>
                    </div>
                    <hr />
                    <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: "700" }}>
                        To View this Movie, Please install Desktop Application.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        1. To install Desktop App, click the download button.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        2. Once download has been completed, Extract the Zip with the option Extract to Gujju Stream
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        3. Run gujju_stream.exe after extracting the file from the zip file.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        4. Pin gujju_stream.exe to your taskbar and enjoy streaming.
                    </Typography>
                    <div className='pt-3' style={{ display: "flex", justifyContent: "end" }}>
                        <button className="btn btn-hover mb-1 iq-button" style={{ marginBottom: "10px" }}>
                            Download
                        </button>

                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
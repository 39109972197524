import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Navbar, Dropdown, Form, Nav } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Card from '../../../../components/Card'
// import logo from '../../../../'
import axios from "axios";
import CustomToggle from '../../../../components/dropdowns'
import Drawer from '@mui/material/Drawer';
//img
import { logout, notification_read_status_update, user_login_status, user_notification } from '../../../../actions/HomeActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { user_profile_list } from "../../../../actions/HomeActions"
import addProfile from "../../../../assets/regal_images/pro_add.png"
// import logo from '../../../../assets/regal_images/gujju_Stream_logo2.png';
import logo from '../../../../assets/regal_images/regal_logo.png';
import thumb1 from '../../../../assets/images/notify/thumb-1.jpg'
import thumb2 from '../../../../assets/images/notify/thumb-2.jpg'
import thumb3 from '../../../../assets/images/notify/thumb-3.jpg'
import user from '../../../../assets/regal_images/user_icon.png'
import { ROUTES } from '../../../../constant/routes'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import * as MovieAction from '../../../../actions/MovieAction'
import myAcc from '../../../../assets/regal_images/My Account.png'
import watchlatter from '../../../../assets/regal_images/Watch Later.png'
import complaintImg from '../../../../assets/regal_images/complain.png'
import contentImg from '../../../../assets/regal_images/document.png'
import contentImg1 from '../../../../assets/regal_images/exam.png'
import demandImg from '../../../../assets/regal_images/request.png'
import giftImg from '../../../../assets/regal_images/gift.png'
import logoutImg from '../../../../assets/regal_images/exit.png'
import LoginImg from '../../../../assets/regal_images/enter.png'
import AlertMessage from '../../../../views/backend/common/AlertMessage';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { LOGOUT_MESSAGE } from '../../../../Actiontype/ActionType';
import { Badge, Grid, Tooltip } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
// import myZip from '../../../../sugar_ott-win32-x64.zip'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { bindActionCreators } from 'redux';
import * as HomeAction from '../../../../actions/HomeActions'
import { Base64 } from 'js-base64';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const CustomToggle1 = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));
const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));
const HeaderStyle1 = (props) => {
    const [open, setOpen] = React.useState(false);
    var isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const [form, setForm] = useState({ user: user_id, user_id: user_id })
    const [showNotification, setShowNotification] = useState()
    const [openLogoutDialogue, setLogoutDialogue] = useState(false)
    // const genres = useSelector((state) => state?.home?.homeData?.data?.Genre)
    // const languages = useSelector((state) => state?.home?.homeData?.data?.Language)
    const [languages, setLanguages] = useState([])
    const [genres, setGenres] = useState([])
    const [genrelist, setGenreList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [download, setDownload] = useState(false);

    const [ip, setIP] = useState("")
    const dispatch = useDispatch()
    let history = useHistory()
    const location = useLocation()
    const { getSearchSuggestion } = MovieAction
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const dropdownRef = useRef(null);
    const dropdownRef1 = useRef(null);
    // const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const { getAllGenreList } = bindActionCreators(MovieAction, dispatch)
    const { getAllLanguageList } = bindActionCreators(MovieAction, dispatch)
    const [home, setHome] = useState([])



    useEffect(() => {
        (async () => {
            const resData = await getAllGenreList();
            setGenreList(resData?.data?.genre_list);
            const resData1 = await getAllLanguageList()
            setLanguageList(resData1?.data?.language_list)
        })();
    }, [])


    // console.log("lan List", genrelist)


    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip)
        setForm({ ...form, ip_address: res.data.ip, user_id: user_id });
    };
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        (async () => {
            if (isLoggedIn && user_id) {
                const res = await axios.get("https://api.ipify.org/?format=json");
                let userObj = {
                    ip_address: res.data.ip,
                    user_id: user_id
                }
                dispatch(user_login_status(userObj))
            }
            isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
        })();

    }, [location?.pathname]);

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
        if (dropdownRef1.current && !dropdownRef1.current.contains(e.target)) {
            setIsOpen1(false)
        }
    };
    const handleMouseMove = (event) => {
        // Check if the mouse cursor is outside the component's boundaries
        if (!dropdownRef.current.contains(event.target)) {
            // Your logic for handling movement outside the component
            // setIsOpen1(false)
            setIsOpen(false);

        }
        if (!dropdownRef1.current.contains(event.target)) {
            // Your logic for handling movement outside the component
            // setIsOpen1(false)
            setIsOpen1(false);

        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('mousemove', handleMouseMove);

        };
    }, []);
    const [installPromptEvent, setInstallPromptEvent] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault(); // Prevent Chrome from automatically showing the prompt
            setInstallPromptEvent(event); // Store the event for later use
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);


    const [search, setSearch] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const [isOpen2, setIsOpen2] = useState(false); // State to manage Navbar toggle

    const [read, setRead] = useState(0)
    const profiles = useSelector((state) => state.home.profiles);
    const logout_message = useSelector((state) => state.home.logout_message);
    const notifications = useSelector((state) => state.home.notifications);
    useMemo(() => {
        if (logout_message?.statuscode == 200) {
            history.replace('/')
            isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
            toast.success(logout_message?.message)
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', function () {
                window.history.pushState(null, document.title, window.location.href);
            });

            dispatch({ type: LOGOUT_MESSAGE, payload: null })

        }

    }, [logout_message])
    useMemo(() => {

        dispatch(user_profile_list({ id: user_id }))
        dispatch(user_notification({ user_id: user_id }))

    }, [user_id])

    const handleSearchChange = async (event) => {
        setSearchValue(event.target.value)
        let userObj = {
            // user_id: user_id,
            search: event.target.value
        }
        const resData = await getSearchSuggestion(userObj)
        setSearch(resData?.data)
        if (!event.target.value) {
            setSearch(null)
        }
    }

    const handleRedirect = (slug) => {
        history.push(`${ROUTES.moviedetails}/${slug}`)
        setSearchValue("")
        setSearch(null)

        // window.location.reload()
    }

    const handleSeriesRedirect = (slug) => {
        console.log(slug)
        history.push(`${ROUTES.seriesDetails}/${slug}`)
        setSearchValue("")
        setSearch(null)
        // history.push(ROUTES.seriesDetails, { series: id })

    }
    const handleClose = () => {
        setOpen(false)
        setShowNotification()
        setLogoutDialogue(false)
    };

    const handleGenreId = (id) => {
        history.push(ROUTES.moviesByGenre, { id: id })
        setIsOpen(false)
    }
    const handleLanguageId = (id) => {
        history.push(ROUTES.moviesByLanguage, { id: id })
        setIsOpen1(false)

    }



    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleHover = () => {
        setIsOpen(true);
        setIsOpen1(false);

    };

    const handleToggle1 = () => {
        setIsOpen1(!isOpen1);
    };

    const handleHover1 = () => {
        setIsOpen1(true);
        setIsOpen(false)
    };

    const handleToggle2 = () => {
        setIsOpen2(!isOpen2); // Toggle the Navbar toggle state
    };

    const closeNavbarToggle = () => {
        setIsOpen2(false); // Function to close Navbar toggle
    };
    const handleLeave = () => {
        setIsOpen(false);
    };

    const startListening = () => {
        setIsListening(true);

        const recognition = new window.webkitSpeechRecognition(); // Chrome-specific
        recognition.continuous = false;
        recognition.interimResults = false;

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setSearchText(transcript);
            recognition.stop();
            setIsListening(false);
        };

        recognition.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
            setIsListening(false);
        };

        recognition.start();
    };
    // console.log("searchText", searchText)
    const handleInstallClick = () => {
        // if (installPromptEvent) {
        //     installPromptEvent.prompt(); // Show the install prompt
        //     installPromptEvent.userChoice.then((choiceResult) => {
        //         if (choiceResult.outcome === 'accepted') {
        //             console.log('User accepted the install prompt');
        //         } else {
        //             console.log('User dismissed the install prompt');
        //         }
        //         setInstallPromptEvent(null); // Reset the event
        //     });
        // }
        setDownload(false)
        // const myZipFile = require('../../../../gujju_stream-win32-x64.zip');
        // const link = document.createElement('a');
        // link.href = myZipFile;
        // link.download = 'gujju_stream_installer.zip';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
    };










    const [open3, setOpen3] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen3(newOpen);
    };


    const DrawerList = (
        <Box sx={{ width: 330, backgroundColor: "black", minHeight: "auto", borderRight: "1px solid rgb(108, 108, 108)" }} role="presentation" >
            <div className='left-sidebar' >
                <div className='sidebar-section-1'>
                    <img height={100} width={100} src={logo} alt="" />
                    <i onClick={toggleDrawer(false)} style={{ color: "black", padding: "4px 6px", backgroundColor: "white", fontSize: "1.1rem", borderRadius: "11px" }} className="fa fa-times" aria-hidden="true"></i>
                </div>

                <div className='leftsidebar-section-2' style={{ marginTop: "1rem" }}>
                    <Link to={ROUTES.home} onClick={toggleDrawer(false)}>
                        <div className='sidebar-section-2_1'>
                            <p style={{ margin: "0", color: "white" }}>Home</p>
                        </div>
                    </Link>

                    <Link to={ROUTES.movies} onClick={toggleDrawer(false)}>
                        <div className='sidebar-section-2_1'>
                            <p style={{ margin: "0", color: "white" }}>Movies</p>
                        </div>
                    </Link>


                    {/* <Link to={ROUTES.shortSeries} onClick={toggleDrawer(false)}>
                        <div className='sidebar-section-2_1'>
                            <p style={{ margin: "0", color: "white" }}>Short Series</p>
                        </div>
                    </Link> */}


                    <Link to={ROUTES.series} onClick={toggleDrawer(false)}>
                        <div className='sidebar-section-2_1'>
                            <p style={{ margin: "0", color: "white" }}>Web Series</p>
                        </div>
                    </Link>

                    <div style={{ marginTop: "0.5rem" }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: "#000000bb" }}
                            >
                                <span style={{ color: "white", fontSize: "1.14rem" }}>Category</span>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px", backgroundColor: "#000000bb " }}>
                                <div style={{ padding: "2px", backgroundColor: "#000000bb", display: "flex", justifyContent: "space-around", paddingTop: "0.5rem" }}>
                                    <div>
                                        {genrelist?.slice(0, (genrelist?.length / 2) + 1).map((data, index) => (
                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleGenreId(data?.id) }}><li onClick={toggleDrawer(false)} className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", marginBottom: "-21px", textAlign: "left", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.genre_title}</li><br /></div>
                                        ))
                                        }
                                    </div>
                                    <div  >
                                        {genrelist?.slice((genrelist?.length / 2) + 1).map((data, index) => (
                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleGenreId(data?.id) }}><li onClick={toggleDrawer(false)} className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", marginBottom: "-21px", textAlign: "left", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.genre_title}</li><br /></div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>


                    <div style={{ marginTop: "1rem", marginBottom: "22rem" }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: "#000000bb" }}
                            >
                                <span style={{ color: "white", fontSize: "1.14rem" }}>Language</span>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px", backgroundColor: "#000000bb " }}>
                                <div style={{ padding: "2px", backgroundColor: "#000000bb", display: "flex", justifyContent: "space-around", paddingTop: "0.5rem" }}>
                                    <div>
                                        {languageList?.slice(0, (languageList?.length / 2) + 1).map((data, index) => (
                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleLanguageId(data?.id) }}><li onClick={toggleDrawer(false)} className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", marginBottom: "-21px", textAlign: "left", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.language_name}</li><br /></div>
                                        ))
                                        }
                                    </div>
                                    <div  >
                                        {languageList?.slice((languageList?.length / 2) + 1).map((data, index) => (
                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleLanguageId(data?.id) }}><li onClick={toggleDrawer(false)} className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", marginBottom: "-21px", textAlign: "left", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.language_name}</li><br /></div>
                                        ))
                                        }
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
            </div>
        </Box>
    );



    return (
        <>
            {/* <AlertMessage /> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    {!isLoggedIn && <Box sx={style}>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={logo} width={"100px"} />
                        </div>
                        <hr />

                        <div className="login-popup" >
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                Please login to continue.
                            </Typography>
                            <button onClick={() => history.push("/login")} className={"btn btn-hover iq-button mt-3"} style={{ display: "block", margin: "0 auto" }}>
                                Login
                            </button>
                        </div>
                    </Box>}
                    {showNotification && <Box sx={style}><Card sx={{ maxWidth: 345 }}>
                        <Card.Header className="flex-column">
                            <div style={{ cursor: "pointer", position: "absolute", top: "5px", right: "5px" }}>
                                <i style={{ fontSize: "30px", color: "#ba000b" }} className="ri-close-fill" onClick={() => setOpen(false)}></i>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography variant="h6" component="h6" >{showNotification?.title}</Typography>

                                    <Typography variant="body2" >{showNotification?.date}</Typography>

                                </div>

                            </div>

                            <hr style={{ color: "white", width: "100%" }} />
                        </Card.Header>



                        <Card.Body>

                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    {showNotification?.image && <img src={showNotification?.image} style={{ maxHeight: "200px" }} />}
                                </Grid>
                                <Grid item xs={showNotification?.image ? 8 : 12}>
                                    <Typography>
                                        {showNotification?.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card.Body>



                    </Card></Box>}</div>
            </Modal>
            <header id="main-header" className='header-width'>
                <div className="main-header">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                <Navbar expanded={false} expand="lg" className="p-0">
                                    <Button onClick={toggleDrawer(true)} className="c-toggler">
                                        <div className="navbar-toggler-icon"  >
                                            <span className="navbar-menu-icon navbar-menu-icon--top"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--middle"></span>
                                            <span className="navbar-menu-icon navbar-menu-icon--bottom"></span>
                                        </div>
                                    </Button>


                                    <Drawer open={open3} onClose={toggleDrawer(false)}>
                                        {DrawerList}
                                    </Drawer>





                                    <Navbar.Brand className="navbar-brand">
                                        <Link to="/"><img className="img-fluid logo" src={logo} alt="regal-ott" style={{ width: "140px" }} /></Link>
                                    </Navbar.Brand>
                                    <Dropdown className="mobile-more-menu">
                                        <Dropdown.Toggle to="#" as={CustomToggle} variant="more-toggle">
                                            <i className="ri-more-line"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="more-menu">
                                            <div className="navbar-right position-relative">
                                                <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                                                    <Dropdown as="li" className="nav-item m-">
                                                        <div className="cfield">
                                                            <input
                                                                type="text"
                                                                name="search"
                                                                size="20"
                                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true"
                                                                aria-invalid="false"
                                                                placeholder="I am searching for..."
                                                                onChange={(event) => handleSearchChange(event)}
                                                                autoComplete="off"
                                                            />
                                                            <div className={search !== null ? "displayItems" : "displaynone"}>
                                                                {
                                                                    search?.length ?

                                                                        search?.map((data, index) => (
                                                                            // <p style={{ color: "#ffffff", textAlign: "center" }}>{data?.movie_name}</p>
                                                                            <ul key={index} style={{ color: "#ffffff" }}>
                                                                                <div style={{ cursor: "pointer" }} onClick={() => handleRedirect(data?.slug)}><li>{data?.movie_name ? data?.movie_name : data?.series_name}</li></div>
                                                                            </ul>
                                                                        ))
                                                                        :
                                                                        <p style={{ color: "#ffffff", textAlign: "center" }}>No result found.</p>

                                                                }
                                                            </div>
                                                        </div>

                                                    </Dropdown>
                                                    <Dropdown as="li" className="nav-item m-0">
                                                        <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle position-relative">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"
                                                                className="noti-svg">
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                    d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
                                                            </svg>
                                                            <span className="bg-danger dots"></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="iq-sub-dropdown dropdown-menu dropdown-menu-right">
                                                            <Card className="iq-card shadow-none m-0">
                                                                <Card.Body className="iq-card-body">
                                                                    <Link to="#" className="iq-sub-card">
                                                                        <div className="media align-items-center">
                                                                            <img src={thumb1} className="img-fluid mr-3"
                                                                                alt="streamit" />
                                                                            <div className="media-body">
                                                                                <h6 className="mb-0 ">Boot Bitty</h6>
                                                                                <small className="font-size-12"> just now</small>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-sub-card">
                                                                        <div className="media align-items-center">
                                                                            <img src={thumb2} className="img-fluid mr-3"
                                                                                alt="streamit" />
                                                                            <div className="media-body">
                                                                                <h6 className="mb-0 ">The Last Breath</h6>
                                                                                <small className="font-size-12">15 minutes ago</small>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    <Link to="#" className="iq-sub-card">
                                                                        <div className="media align-items-center">
                                                                            <img src={thumb3} className="img-fluid mr-3"
                                                                                alt="streamit" />
                                                                            <div className="media-body">
                                                                                <h6 className="mb-0 ">The Hero Camp</h6>
                                                                                <small className="font-size-12">1 hour ago</small>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </Card.Body>
                                                            </Card>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </ul>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Navbar.Collapse style={{ display: isOpen2 ? "block" : "none" }} className="">
                                        <div className="">
                                            {/* <div className="menu-main-menu-container"> for center navbar*/}

                                            <Nav as="ul" id="top-menu" className="ml-auto">
                                                <li className="menu-item">
                                                    <Link to={ROUTES.home} onClick={closeNavbarToggle}>Home</Link>
                                                </li>
                                                <li className="menu-item" onClick={closeNavbarToggle}>
                                                    <Link to={ROUTES.movies}>Movies</Link>
                                                </li>
                                                {/* <li className="menu-item">
                                                    <Link to={ROUTES.shortfilms}>Short films</Link>
                                                </li> */}
                                                {/* <li className="menu-item">
                                                    <Link to={ROUTES.shortSeries} onClick={closeNavbarToggle}>Short Series</Link>
                                                </li> */}
                                                <li className="menu-item">
                                                    <Link to={ROUTES.series} onClick={closeNavbarToggle}>Web Series</Link>
                                                </li>
                                                {/* <li className="menu-item">
                                                    <Link to={ROUTES.vod}>VOD</Link>
                                                </li> */}
                                                {/* <li className="menu-item">
                                                    <Link to="/live-streaming">Live Tv</Link>
                                                </li> */}


                                                {/* <li className="menu-item">
                                                    <Link to={ROUTES.watchlist}>Watchlist</Link>
                                                </li> */}



                                                {/* <li className="menu-item">
                                                    <Link to="/live-tv">Live Tv</Link>
                                                </li> */}

                                                <li className='manage-top menu-item' ref={dropdownRef}>
                                                    <Dropdown
                                                        show={isOpen}
                                                        onMouseEnter={handleHover}
                                                        onMouseLeave={handleLeave}
                                                        as="li" className="nav-item">
                                                        <Dropdown.Toggle href="#" as={CustomToggle1} variant="search-toggle position-relative" onClick={handleToggle}>
                                                            <span style={{ fontSize: "13px", textTransform: "uppercase", color: "white" }} className='pl-1'>Category</span>
                                                        </Dropdown.Toggle>
                                                        <div className='' style={{ borderRadius: "1rem" }}>
                                                            <Dropdown.Menu className=" hide-scrolls-nav on_hover_openable" align="right" style={{ background: "#000000de", width: "310px", color: "white", borderRadius: "0.5rem" }}>
                                                                <Typography style={{ fontWeight: "500", fontSize: "1.4rem", color: "white", paddingLeft: "22px", marginBottom: "13px", marginTop: "7px" }} >
                                                                    Genres
                                                                </Typography>
                                                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                    <div>
                                                                        {genrelist?.slice(0, (genrelist?.length / 2) + 1).map((data, index) => (
                                                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleGenreId(data?.id); closeNavbarToggle() }}><li className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.genre_title}</li><br /></div>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                    <div  >
                                                                        {genrelist?.slice((genrelist?.length / 2) + 1).map((data, index) => (
                                                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleGenreId(data?.id); closeNavbarToggle() }}><li className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.genre_title}</li><br /></div>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </div>
                                                    </Dropdown>
                                                </li>


                                                <li className='manage-top menu-item' ref={dropdownRef1}>
                                                    <Dropdown
                                                        as="li"
                                                        className="nav-item"
                                                        show={isOpen1}
                                                        onMouseEnter={handleHover1}
                                                        onMouseLeave={handleLeave}
                                                    >
                                                        <Dropdown.Toggle href="#" as={CustomToggle2} variant="search-toggle position-relative" onClick={handleToggle1}>
                                                            <span style={{ fontSize: "13px", textTransform: "uppercase", color: "white" }} className='pl-1'>Language</span>
                                                        </Dropdown.Toggle>
                                                        <div className='' style={{ borderRadius: "1rem" }}>
                                                            <Dropdown.Menu className="hide-scrolls-nav" align="right" style={{ width: "270px", background: "#000000de", color: "white", borderRadius: "0.5rem" }}>
                                                                <Typography style={{ fontWeight: "500", fontSize: "1.4rem", color: "white", paddingLeft: "22px", marginBottom: "13px", marginTop: "7px" }} >
                                                                    Language
                                                                </Typography>
                                                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                    <div>
                                                                        {languageList?.slice(0, (languageList?.length / 2) + 1).map((data, index) => (
                                                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleLanguageId(data?.id); closeNavbarToggle() }}><li className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.language_name}</li><br /></div>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {languageList?.slice((languageList?.length / 2) + 1).map((data, index) => (
                                                                            <div key={index} className='responsive-nav-dpwn-1' onClick={() => { handleLanguageId(data?.id); closeNavbarToggle() }}><li className='responsive-nav-dpwn' key={index} style={{ fontSize: "1rem", fontWeight: "bold", color: "#aaaaaa", cursor: "pointer" }}>{data?.language_name}</li><br /></div>
                                                                        ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </div>
                                                    </Dropdown>
                                                </li>
                                            </Nav>

                                        </div>

                                    </Navbar.Collapse>

                                    <div className="navbar-right menu-right">
                                        <ul className="d-flex align-items-center list-inline m-0">

                                            <div className='search-panel-responsive mr-2'>
                                                <Dropdown as="li" className="nav-item nav-icon search-set">
                                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="search-toggle device-search">
                                                        <div className='search-box-panel'>
                                                            <input
                                                                // autoFocus
                                                                type="text"
                                                                name="search-value"
                                                                size="50"
                                                                value={searchValue}
                                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="false"
                                                                aria-invalid="false"
                                                                style={{ fontSize: "13px" }}
                                                                placeholder={`Search...`}
                                                                onChange={(event) => handleSearchChange(event)}
                                                                autoComplete={"off"}
                                                            />
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    {/* <Dropdown.Menu className="search-box iq-search-bar d-search p-0 m-0 on_hover_openable mt-3" align="right"> */}
                                                    <Dropdown.Menu className="p-0 m-0 mt-3" align="right">

                                                        <div className="cfield">

                                                            <div className={`hide-scrolls ${search !== null ? "displayItems" : "displaynone"}`}>
                                                                {
                                                                    search?.length ?

                                                                        search?.map((data, index) => (
                                                                            // <p style={{ color: "#ffffff", textAlign: "center" }}>{data?.movie_name}</p>
                                                                            <ul key={index} className='pl-3 hover-bg-change' style={{ color: "#ffffff", padding: "9px" }} >
                                                                                <div className='hover-bg-change' style={{ cursor: "pointer" }} onClick={data?.category_type == "Movie" ? () => handleRedirect(data?.slug) : () => handleSeriesRedirect(data?.slug)}><li>{data?.movie_name ? data?.movie_name : data?.series_name}</li></div>
                                                                            </ul>
                                                                        ))
                                                                        :
                                                                        <p style={{ color: "#ffffff", textAlign: "center" }}>No result found.</p>

                                                                }
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>

                                                </Dropdown>
                                            </div>

                                            <div className='search-icon-responsive pr-3'>
                                                <Dropdown as="li" className="nav-item nav-icon search-set">
                                                    <Dropdown.Toggle as={CustomToggle} href="#" variant="search-toggle device-search">
                                                        <i className="ri-search-line"></i>
                                                    </Dropdown.Toggle>
                                                    {/* <Dropdown.Menu className="search-box iq-search-bar d-search p-0 m-0 on_hover_openable" align="right"> */}

                                                    <Dropdown.Menu className="p-0 m-0 on_hover_openable" align="right">
                                                        <div className="cfield">
                                                            <div className='search-box-panel' style={{ borderRadius: "0.5rem" }}>
                                                                <input
                                                                    autoFocus
                                                                    type="text"
                                                                    name="search"
                                                                    size="10"
                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    placeholder={`I am searching for...`}
                                                                    onChange={(event) => handleSearchChange(event)}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className={`hide-scrolls ${search !== null ? "displayItems" : "displaynone"}`}>
                                                                {
                                                                    search?.length ?

                                                                        search?.map((data, index) => (
                                                                            // <p style={{ color: "#ffffff", textAlign: "center" }}>{data?.movie_name}</p>
                                                                            <ul key={index} className='pl-3 hover-bg-change' style={{ color: "#ffffff", padding: "9px" }} >
                                                                                <div style={{ cursor: "pointer" }} onClick={data?.category_type == "Movie" ? () => handleRedirect(data?.slug) : () => handleSeriesRedirect(data?.slug)}><li>{data?.movie_name ? data?.movie_name : data?.series_name}</li></div>
                                                                            </ul>
                                                                        ))
                                                                        :
                                                                        <p style={{ color: "#ffffff", textAlign: "center" }}>No result found.</p>

                                                                }
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>

                                                </Dropdown>
                                            </div>


                                            <Dropdown as="li" className="nav-item">
                                                <Dropdown.Toggle href="#" as={CustomToggle} variant="search-toggle position-relative">

                                                    <Badge badgeContent={notifications?.unread_count} color="primary">
                                                        <NotificationsActiveIcon color="action" sx={{ color: "#cfcecd" }} />
                                                    </Badge>
                                                </Dropdown.Toggle>
                                                <div className='remove-bg-notification '>

                                                    <Dropdown.Menu className="iq-sub-dropdown on_hover_openable " align="right">
                                                        <Card className="shadow-none m-0 notification-dropdown">
                                                            <Card.Body>

                                                                {
                                                                    notifications?.data?.length ?
                                                                        notifications?.data?.slice(0, 5)?.map((ele, index) =>
                                                                            <div className='mt-2 ' key={index} style={{ background: "black", borderRadius: "1rem" }}>
                                                                                <Link
                                                                                    to="#"
                                                                                    className="iq-sub-card notification-message "
                                                                                    onClick={() => {
                                                                                        setShowNotification({
                                                                                            image: ele?.notification?.notification_image ? IMAGE_ENDPOINT + ele?.notification?.notification_image : null,
                                                                                            title: ele?.notification?.notification_title,
                                                                                            description: ele?.notification?.description,
                                                                                            date: new Date(ele?.notification?.created_at).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })
                                                                                        })
                                                                                        // const data = new FormData
                                                                                        let userObj = {
                                                                                            notification: ele?.notification?.id,
                                                                                            user: user_id
                                                                                        }
                                                                                        // data.append("notification", ele?.id)
                                                                                        // data.append("user", user_id)
                                                                                        dispatch(notification_read_status_update(userObj))
                                                                                        setOpen(true)
                                                                                    }}
                                                                                    style={{ border: ele?.notification_read == "false" ? "white 3px solid" : "none", borderRadius: "1rem" }}
                                                                                >
                                                                                    <div className="media align-items-center ">
                                                                                        {
                                                                                            ele?.notification?.notification_image &&
                                                                                            <img style={{ maxHeight: "40px", maxWidth: "40px" }} src={`${IMAGE_ENDPOINT + ele?.notification?.notification_image}`} className="img-fluid mr-3"
                                                                                                alt="streamit" />
                                                                                        }

                                                                                        <div className="media-body">
                                                                                            <h6 className="mb-0 font-size-12">{ele?.notification?.notification_title}</h6>
                                                                                            <small className="font-size-12"> {ele?.notification?.description.slice(0, 20)}{ele?.notification?.description.length > 20 && "..."}</small>
                                                                                        </div>
                                                                                        <small className="font-size-12" style={{ float: "right" }}>{new Date(ele?.notification?.created_at).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })} </small>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        )
                                                                        :
                                                                        <>
                                                                            <Link to="#"
                                                                                className="iq-sub-card notification-message"
                                                                            >
                                                                                <div className="media align-items-center">

                                                                                    <div className="media-body" style={{ background: "#000000c4", padding: "15px", borderRadius: "1rem" }}>
                                                                                        <h6 className="mb-0 font-size-12" >No notification found.</h6>
                                                                                    </div>

                                                                                </div>
                                                                            </Link>
                                                                        </>}


                                                            </Card.Body>
                                                        </Card>
                                                    </Dropdown.Menu>
                                                </div>
                                            </Dropdown>
                                            <Dropdown as="li" className="nav-item nav-icon">
                                                <Dropdown.Toggle href="#" as={CustomToggle} variant="profile-toggle">
                                                    <div className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active"
                                                        data-toggle="search-toggle">
                                                        <img src={user} className="img-fluid avatar-40" alt="user" style={{ borderRadius: ".8rem" }} />
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown align-menu-right on_hover_openable" id="user_menu" onMouseLeave={(e) => { document.getElementById("user_menu").classList.remove("show") }} align="right">
                                                    <Card className="shadow-none m-0">
                                                        {isLoggedIn && <Card.Header>
                                                            {profiles?.data?.map((ele, index) => index <= 3 && <div key={index} style={{ margin: "0.5rem" }} >

                                                                <img src={IMAGE_ENDPOINT + ele?.avatar?.avatarImage} onClick={() => {

                                                                    sessionStorage.setItem("profile", JSON.stringify(ele))
                                                                    history.push("/")

                                                                }
                                                                } style={{ maxWidth: "50px", marginBottom: "0", cursor: "pointer" }} className="rounded img-fluid d-block mx-auto " alt="user" />
                                                                <p style={{
                                                                    textAlign: "center",
                                                                    color: JSON.parse(sessionStorage.getItem("profile"))?.id == ele?.id ? "white" : "grey",
                                                                    fontSize: "9px", marginBottom: "0"
                                                                }}>{ele?.profileName || "No Name"}</p>
                                                            </div>)}
                                                            {Array(4).fill("a").map((ele, index) => index <= 3 - profiles?.data?.length && <div key={index} style={{ margin: ".5rem" }} >
                                                                <img src={addProfile} onClick={() => (history.push("/profile"))} style={{ maxWidth: "40px", marginBottom: "0", cursor: "pointer" }} className="rounded img-fluid d-block mx-auto" alt="user" />
                                                                <p style={{ textAlign: "center", color: "grey", fontSize: "9px", marginBottom: "0" }}>Add profile</p>
                                                            </div>)}
                                                        </Card.Header>}
                                                        {/* <div className='d-flex justify-content-center' >
                                                        {isLoggedIn &&<button  onClick={()=>(history.push("/profile_manage"))} className="btn btn-hover iq-button">Manage Profiles</button>}

                                                        </div>  */}
                                                        <Card.Body className="p-0 pl-3 pr-3 ">
                                                            {!isLoggedIn && <Link to="/login" className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={LoginImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Login / Register</p>
                                                                    </div>
                                                                </div>
                                                            </Link>}
                                                            <div onClick={() => isLoggedIn ? history.push(ROUTES.my_account) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        {/* <i className="ri-file-user-line text-primary"></i> */}
                                                                        <img src={myAcc} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">My Account</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div onClick={() => isLoggedIn ? history.push(ROUTES.watchlist) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={watchlatter} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Watch Later</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => isLoggedIn ? history.push(ROUTES.complaint) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={complaintImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Help & Support</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => window.open("https://admin.24sevenflix4u.co.uk/Digital_Submission_form", "__blank")} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={contentImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Digital Content Submission</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => window.open("https://admin.24sevenflix4u.co.uk/Film_Festival_Submission_form", "__blank")} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={contentImg1} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Film Festival Submission</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div onClick={() => isLoggedIn ? history.push(ROUTES.demandMovie) : setOpen(true)} className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={demandImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">You Demand, We Deliver</p>
                                                                    </div>
                                                                </div>
                                                            </div> */}


                                                            {isLoggedIn && <div
                                                                // onClick={() => { dispatch(logout(form)) }}
                                                                onClick={() => setLogoutDialogue(true)}
                                                                className="iq-sub-card setting-dropdown">
                                                                <div className="media align-items-center">
                                                                    <div className="right-icon">
                                                                        <img src={logoutImg} alt='acc' className='menu-img' />
                                                                    </div>
                                                                    <div className="media-body ml-3">
                                                                        <p className="my-0 ">Logout</p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </Card.Body>
                                                    </Card>
                                                </Dropdown.Menu>
                                            </Dropdown>



                                        </ul>

                                    </div>
                                    {/* <div href="#" style={{ cursor: "pointer" }} className='install-app pl-2' variant="search-toggle position-relative">
                                        <Tooltip title="Install Desktop App">
                                            <BrowserUpdatedIcon onClick={() => setDownload(true)} color="action" sx={{ color: "#cfcecd" }} />
                                        </Tooltip>
                                    </div> */}
                                </Navbar>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal
                    open={openLogoutDialogue}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                        </div>
                        <hr />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to logout?
                        </Typography>
                        <div className='pt-3' style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button className="btn btn-hover iq-button" onClick={() => { dispatch(logout(form)); setLogoutDialogue(false) }}>
                                Yes
                            </button>
                            <button onClick={handleClose} className="btn btn-hover iq-button ml-2">
                                No
                            </button>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={download}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style1}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                        </div>
                        <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                            <div onClick={() => setDownload(false)}>
                                <HighlightOffIcon style={{ height: "40px", cursor: "pointer" }} />
                            </div>
                        </div>
                        <hr />
                        <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight: "700" }}>
                            Installation steps to setup desktop app for Gujju Stream
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            1. To install Desktop App, click the download button.
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            2. Once download has been completed, Extract the Zip with the option Extract to Gujju Stream
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            3. Run gujju_stream.exe after extracting the file from the zip file.
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            4. Pin gujju_stream.exe to your taskbar and enjoy streaming.
                        </Typography>
                        <div className='pt-3' style={{ display: "flex", justifyContent: "end" }}>
                            <button onClick={handleInstallClick} className="btn btn-hover mb-1 iq-button" style={{ marginBottom: "10px" }}>
                                Download
                            </button>
                            {/* <button onClick={() => setDownload(false)} className="btn btn-hover iq-button ml-2">
                                No
                            </button> */}
                        </div>
                    </Box>
                </Modal>
            </header>
        </>
    )
}

export default HeaderStyle1

import axios from 'axios'
import React from 'react'

export default function Paypal() {

    const handlePayment = async () => {
        const resData = await axios.post('http://192.168.0.104:4000/payment')
        console.log("Res", resData)
        if(resData){
            let link = resData?.data?.links?.[1].href
            window.location.href = link
        }
    }
    return (
        <div>
            <button onClick={handlePayment}>Pay now</button>
        </div>
    )
}
